.container-404 {
  display: flex;
  align-items: center;
  background-image: linear-gradient(to top, rgb(172, 203, 238) 0%, rgb(161, 184, 218) 100%);
  transition: transform 0.7s cubic-bezier(0.47, 0.04, 0.22, 0.92),height 0.2s ease,width 0.2s ease;
  min-width: 275px;
  height: 100vh;
  overflow: hidden;
  color: #ffffff;
  font-family: verdana;

  *, :before, :after {
    box-sizing: border-box;
  }
  
  .wrapper {
    max-width: 500px;
    margin: 0 auto;
  }
  
  h1 {
    margin: 0 0 0.1em 0;
    font-size: 10em;
    font-weight: 600;
  }
  h3{
    margin: 0;
    font-size: 2.5em;
    font-weight: 100;
  }
  
  p {
    font-size: 1.5em;
    margin-top: 0.5em;
    color: #e3efff;
  }
  
  .buttons {
    white-space: nowrap;
    display: inline-block;
  }
  
}

