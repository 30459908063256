.loader-container {
  position: relative;

  $green: #008744;
  $blue: #0057e7;
  $red: #d62d20;
  $yellow: #ffdb00;
  $white: rgb(255, 255, 255);
  $width: 140px; // scaling... any units

  .loader {
    position: absolute;
    /* margin: 0 auto; */
    width: 140px;
    height: 140px;
    /* margin: 0 auto; */
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    100%,
    0% {
      stroke: $yellow;
    }
    40% {
      stroke: $yellow;
    }
    66% {
      stroke: $yellow;
    }
    80%,
    90% {
      stroke: $yellow;
    }
  }
}