@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import '~react-medium-image-zoom/dist/styles.css';
@import "~react-notifications-component/dist/scss/notification.scss";
@import "~office-ui-fabric-react/dist/sass/mixins/Grid.Mixins";
@import "~office-ui-fabric-react/dist/sass/mixins/General.Mixins";
@import "~office-ui-fabric-react/dist/sass/mixins/Directionality.Mixins";
@import "~office-ui-fabric-react/dist/sass/mixins/Responsive.Mixins";

@import "~office-ui-fabric-react/dist/sass/variables/Responsive.Variables";

@import "~office-ui-fabric-react/dist/sass/_Grid.scss";
@import "~office-ui-fabric-react/dist/sass/_Responsive.scss";

@font-face {
  font-family: "Noto IKEA Latin";
  src: url("./fonts/NotoIKEALatin/NotoIKEALatin-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Noto IKEA Latin Bold";
  src: url("./fonts/NotoIKEALatin/NotoIKEALatin-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Lively Headline";
  src: url("./fonts/LivelyFont/LivelyHeadline-Bold.ttf") format("truetype");
}


html { height: 100%; overflow:auto; }

body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lively Headline', 'Noto IKEA Latin';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  height: 100%;
  width: 100%;
  background-color: #e9e9e9;
}

#root {
  width: 100%;
  height: 100%;
  background-color: #F7F4EF;
}

.no-user-select{
  user-select: none;
}

// done to increase notification z index
.notification-container-top-center,
.notification-container-bottom-center,
.notification-container-top-left,
.notification-container-top-right,
.notification-container-bottom-left,
.notification-container-bottom-right,
.notification-container-mobile-top,
.notification-container-mobile-bottom {
  position: fixed;
  z-index: 10000000000000000;
}
.notification-message {
  font-family: 'Noto IKEA Latin';
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row  {
  font-family: 'Noto IKEA Latin';
  color: #474747;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row:last-of-type  {
    border-bottom-left-radius: 20px;
}

.react-calendar-timeline .rct-outer {
  border-bottom-right-radius: 20px;
}

.react-calendar-timeline .rct-calendar-header {
  border-top-right-radius: 20px;
  color: #474747;
}

.ms-BasePicker-text::after {
  border: none;
  border-radius: 20px;
}

.ms-Persona-primaryText {
  font-family: 'Noto IKEA Latin';
}

.ms-Persona-secondaryText {
  font-family: 'Noto IKEA Latin';
}

.ms-TagItem-text {
  font-family: 'Noto IKEA Latin';
}

.ms-TagItem-TextOverflow {
  font-family: 'Noto IKEA Latin';
}

.ms-Suggestions-item::after {
  border: none;
}

.ms-DatePicker-frame {
  font-family: 'Noto IKEA Latin';
}

*:focus::after {
  border: none !important;
}

.ms-Toggle.is-checked.is-enabled:hover {
  .ms-Toggle-background {
    background-color: #8EBDA7;
  }
  .ms-Toggle-thumb {
    background-color: #05676A;
  }
}

.ms-Toggle.is-enabled:hover {
  .ms-Toggle-background {
    background-color: #D7CFC6;
  }
  .ms-Toggle-thumb {
    background-color: #FFFFFF;
  }
}

.ms-TextField-errorMessage {
  font-family: 'Noto IKEA Latin';
  color: #E30613;
}

.ms-Checkbox-checkbox {
  margin-left: 4px;
}